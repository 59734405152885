const loaderDivMixin = {
    methods: {
        openLoading(){
            this.$vs.loading({
                container: '#div-with-loading',
                scale: 0.6
            })
        },
        closeLoading() {
            this.$vs.loading.close('#div-with-loading > .con-vs-loading')
        },
    }
}

export default loaderDivMixin;