<template>
    <div :style="{'direction': $vs.rtl ? 'rtl' : 'ltr'}">
      <feather-icon icon="EyeIcon" svgClasses="h-5 w-5 mr-4 hover:text-success cursor-pointer" @click="viewRecord"  />
      <feather-icon icon="Edit3Icon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="editRecord" />
      <feather-icon icon="Trash2Icon" svgClasses="h-5 w-5 hover:text-danger cursor-pointer" @click="confirmDeleteRecord" />
    </div>
</template>

<script>
  import {mapActions} from 'vuex';
  import loaderMixin from '../../../../mixins/loaderMixin';
    export default {
        mixins: [loaderMixin],
        name: 'CellRendererActions',
        methods: {
        ...mapActions('hotel', [
          'deleteHotelAction',
          'fetchAndSetHotels'
        ]),
          viewRecord() {
            this.$router.push("hotels/" + this.params.data.id + "/view");

            /*
              Below line will be for actual product
              Currently it's commented due to demo purpose - Above url is for demo purpose

              this.$router.push("/apps/user/user-edit/" + this.params.data.id).catch(() => {})
            */
          },
          editRecord() {
            this.$router.push("hotels/" + this.params.data.id + "/edit");

            /*
              Below line will be for actual product
              Currently it's commented due to demo purpose - Above url is for demo purpose

              this.$router.push("/apps/user/user-edit/" + this.params.data.id).catch(() => {})
            */
          },
          confirmDeleteRecord() {
            this.$vs.dialog({
              type: 'confirm',
              color: 'danger',
              title: `Confirm Delete`,
              text: `You are about to delete "${this.params.data.id}"`,
              accept: this.deleteRecord,
              acceptText: "Delete"
            })
          },
          deleteRecord() {
            /* Below two lines are just for demo purpose */
            this.openLoading();
            this.deleteHotelAction(this.params.data.id).then(() => this.showDeleteSuccess());

            /* UnComment below lines for enabling true flow if deleting user */
            // this.$store.dispatch("userManagement/removeRecord", this.params.data.id)
            //   .then(()   => { this.showDeleteSuccess() })
            //   .catch(err => { console.error(err)       })
          },
          showDeleteSuccess() {
          let params = new URLSearchParams();
          params.append("page", this.page);
          this.fetchAndSetHotels({query: params})
            .then(() => this.closeLoading()).catch((error) => this.closeLoading());
            this.$vs.notify({
              color: 'success',
              title: 'Hotel Deleted',
              position: 'top-right',
              text: 'The selected user was successfully deleted'
            })
          }
        }
    }
</script>
